import { useEffect } from 'react'
import { graphql, navigate } from 'gatsby'

import isBrowser from '../utils/isBrowser'
import isLocaleAvailable from '../utils/isLocaleAvailable'

const IndexPage = () => {
  useEffect(() => {
    if (isBrowser) {
      const navigatorLocale = navigator.language.substring(0, 2)
      const locale = isLocaleAvailable(navigatorLocale) ? navigatorLocale : 'en'
      navigate(locale, { replace: true })
    }
  }, [])

  return null
}

export default IndexPage

export const pageQuery = graphql`
  fragment PageData on wordpress__PAGE {
    title
    content
  }

  fragment PageTranslations on wordpress__PAGE {
    polylang_translations {
      polylang_current_lang
      slug
    }
  }

  fragment BeerTranslations on wordpress__wp_beer_type {
    polylang_translations {
      polylang_current_lang
      slug
    }
  }

  fragment NextStep on wordpress__PAGEAcf {
    text_kam_dal
    popisek_tlacitka_kam_dal
    odkaz_kam_dal
  }
`
